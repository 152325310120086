import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/productos/planes-servicio`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(plan) {
    return apiClient.post(`${plan.id}`, plan)
  },

  eliminar(idPlan) {
    return apiClient.delete(`${idPlan}`)
  },

  guardar(plan) {
    return apiClient.post('', plan)
  },

  planJSON(idPlan) {
    return apiClient.get(`${idPlan}.json`)
  },

  planesJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/productos/planes-servicio.json`, {
      params: params
    })
  }
}
