<template>
  <Layout :tituloPagina="'Network | Planes de servicio'">
    <div class="row">
      <div class="col-lg-5">
        <cmp-secciones seccion="planes"></cmp-secciones>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2 col-5">
        <div class="form-check form-switch form-switch-lg mb-3">
          <input
            :checked="activo"
            @change="
              activo = !activo,
              refrescarPlanes()
            "
            class="form-check-input"
            type="checkbox"
            id="banderaActivo"
          />
          <label
            class="form-check-label"
            for="banderaActivo"
          >
            Activos
          </label>
        </div>
      </div>
      <div class="col-md-8 mb-3 col-7">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarPlanes()
          "
          :datoini="dato"
          placeholder="Datos del plan de servicio"
        ></filtrador>
      </div>

      <div class="col-md-2 text-right">
        <router-link
          class="btn btn-success"
          :to="{ name: 'nuevoPlanServicio' }"
        >
          <i class="mdi mdi-plus-thick"></i>
          Nuevo
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 offset-md-8 text-right" style="padding-top: 10px;">
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nPlanesEnPag}} planes en página
        </span>
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nPlanesTotales}} planes en total
        </span>
      </div>
    </div>
    <br />
    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table class="table table-hover table-nowrap" id="tabla-planes">
            <thead>
              <tr>
                <th style="min-width: 200px">Nombre</th>
                <th>Descarga</th>
                <th>Subida</th>
                <th style="width: 100px">Precio</th>
                <th>Estado</th>
                <th style="width: 180px">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="plan in planes" :key="plan.id">
                <td>{{ plan.nombre }}</td>
                <td>
                  <span v-show="plan.velocidad_descarga > 0">
                    {{ plan.velocidad_descarga }}
                    {{ plan.unidad_descarga == 'k' ? 'kbps' : '' }}
                    {{ plan.unidad_descarga == 'M' ? 'Mbps' : '' }}
                  </span>
                  <span class="badge bg-info bg-gradient font-size-13" v-show="plan.velocidad_descarga == 0">
                    Ilimitado
                  </span>
                </td>
                <td>
                  <span v-show="plan.velocidad_carga > 0">
                    {{ plan.velocidad_carga }}
                    {{ plan.unidad_carga == 'k' ? 'kbps' : '' }}
                    {{ plan.unidad_carga == 'M' ? 'Mbps' : '' }}
                  </span>
                  <span class="badge bg-info bg-gradient font-size-13" v-show="plan.velocidad_carga == 0">
                    Ilimitado
                  </span>
                </td>
                <td class="text-right">{{ hrr.fmon(plan.precio, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}</td>
                <td>
                  <span class="badge bg-success bg-gradient font-size-13" v-show="plan.activo">
                    Activo
                  </span>
                  <span class="badge bg-danger bg-gradient font-size-13" v-show="!plan.activo">
                    Inactivo
                  </span>
                </td>
                <td>
                  <router-link
                    class="btn btn-warning btn-sm"
                    :to="{
                      name: 'edicionPlanServicio',
                      params: { id: plan.id }
                    }"
                  >
                    <i class="mdi mdi-square-edit-outline font-size-13"></i>
                    Editar
                  </router-link>

                  <button
                    class="btn btn-danger btn-sm"
                    @click="preguntaEliminar(plan)"
                  >
                    <i class="mdi mdi-trash-can-outline font-size-13"></i>
                    Eliminar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultima_pagina"
        :epp="porPagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          porPagina = $event,
          refrescarPlanes()
        "
      ></paginador>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import CmpSecciones from './CmpSecciones.vue'
import PlanServicioSrv from '@/services/PlanServicioSrv.js'
import Swal from 'sweetalert2'
export default {
  name: 'ListadoPlanesServicio',
  components: {
    Layout,
    CmpSecciones,
    Paginador,
    Filtrador
  },
  data() {
    return {
      hrr: iu.hrr,
      dato: '',
      pagina: 1,
      porPagina: parseInt(localStorage.getItem('argusblack.listadoPlanesServicio.por_pagina') || 10),
      total: 1,
      ultima_pagina: 1,
      planes: [],
      activo: true,
      nPlanesEnPag:0,
      nPlanesTotales:0
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    var self = this

    // Carga de Querys
    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.activo) this.activo = this.$route.query.activo == 'true' ? true : false
    if (this.$route.query.pagina) this.pagina = parseInt(this.$route.query.pagina)
    if (this.$route.query.por_pagina) this.porPagina = parseInt(this.$route.query.por_pagina)

    self.refrescarPlanes()
  },

  methods: {
    cargarPagina: function(n) {
      this.pagina = n
      this.refrescarPlanes()
    },
    
    preguntaEliminar: function(plan) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminación de plan",
        html: '¿Está seguro que desea eliminar el plan <strong>'+plan.nombre+'</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {

          PlanServicioSrv.eliminar(plan.id).then(response=>{
            swal.fire("Eliminado!", "Se eliminó correctamente", "success")
            self.refrescarPlanes()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar el plan'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    refrescarPlanes: function() {
      var self = this

      // Actualización de los querys
      iu.spinner.mostrar('#tabla-planes')

      this.$router.replace({
        query: {
          dato: self.dato,
          activo: self.activo,
          pagina: self.pagina,
          por_pagina: self.porPagina
        }
      })

      let params = {
        dato: self.dato,
        pagina: self.pagina,
        por_pagina: self.porPagina,
        activo: self.activo
      }

      PlanServicioSrv.planesJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoPlanesServicio.por_pagina', self.porPagina)
        let res = response.data
        self.pagina = res.pagina
        self.total = res.total
        self.ultima_pagina = res.ultima_pagina
        self.planes = res.planes
        self.nPlanesEnPag = res.nPlanesEnPag
        self.nPlanesTotales = res.total
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron refrescar los planes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tabla-planes')
      })
    }
  },
  mounted() {
    iu.spinner.mostrar('#tabla-planes')
  }
}
</script>